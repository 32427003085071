.hpHeader {
    background-color: #282c34;
    min-height: 60px;
    justify-content: space-between;
    color: white;
    display: flex;
    align-items: center;
    font-size: 16px;
}
.buttons {
    display: flex;
    flex-direction: row;
    padding-right: 20px;
    gap: 10px;
}
h1 {
    margin: 0;
    padding: 20px;
}

.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 30px 0 0 0;
}