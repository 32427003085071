/* src/components/Header.module.css */

.header {
    background-color: #f1f1f1;
}

.navList {
    list-style: none;
    display: flex;
    gap: 20px;
    padding: 0;
    margin: 0;
}

.navList li {
    margin: 0;
}

.navList a {
    color: black;
    text-decoration: none;
    font-weight: bold;
}

.profileInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin-left: 20px;
}

.profileButtons {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin-right: 20px;
}
  
/* Responsive adjustments */
@media (max-width: 768px) {
    header {
        flex-direction: row;
        padding: 10px 0px;
    }

    .profileInfo {
        display: none;
    }
    .profileButtons {
        gap: 5px;
        margin: 0;
        padding: 0;

    }
    .navList {
        flex-direction: row;
        margin: 10px;
        gap: 10px;
    }

    .navList a {
        font-size: 16px;
    }
}

@media (max-width: 480px) {
    .navList a {
        font-size: 14px;
    }
}