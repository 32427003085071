/* src/components/Login.module.css */
.container { 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f2f5;
}

form {
  display: flex;
  flex-direction: column;
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

form input {
  margin-bottom: 10px;
  border: 1px solid #ddd;
  width: calc(100% - 20px);
  margin: 10px 20px 10px 0;
  border-radius: 20px;
  height:auto;
}

button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

h2 {
  margin-bottom: 20px;
  color: #333;
}