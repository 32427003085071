/* src/components/Dashboard.module.css */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profileInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-left: 20px;
}

.profileButtons {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-right: 20px;
}

header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 20px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h2 {
  margin-bottom: 20px;
  color: #333;
}

button {
  padding: 10px 20px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #c82333;
}
