
.chart {
  min-width: 350px;
}

.container {
  display: grid;
  text-align: center;
  justify-content: center;
  gap: 50px;
  grid-template: 
  'h1 button'
  'subStats sublist' ;
}

.buttons {
  margin-top: 25px;
  justify-self: center;
  max-width: fit-content;
}

.popup {
  border: 5px solid #ccc;
  padding: 20px;
}
@media screen and (max-width: 768px) {
  .container {
    display: grid;
    grid-template: 
    'h1'
    'button'
    'subStats' 
    'sublist' ;
    gap: 20px;
  }
  .chart {
    height: 400px;
  }
}