/* src/components/SubscriptionForm.module.css */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    border: 2px solid #ddd;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.formGroup {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    width: 100%;
    max-width: 400px;
}

.formGroup label {
    flex: 1;
    margin-right: 10px;
}

.formGroup input,
.formGroup select {
    flex: 2;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}
