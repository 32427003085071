/* src/components/Settings.module.css */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.userInfo {
  margin-bottom: 20px;
}

.userInfo p {
  margin: 5px 0;
}

.formGroup {
  margin-bottom: 15px;
  width: 100%;
  max-width: 400px;
}

.formGroup label {
  display: block;
  margin-bottom: 5px;
}

.formGroup input, .formGroup select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
