/* src/components/ShiftForm.module.css */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  border: 2px solid #ddd;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.shiftAddedContainer {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  border: 2px solid #28a745;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: white;
  transition: display 4s allow-discrete;
}
input, select {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
}

p {
  align-self: flex-start;
  margin-bottom: 5px;
  margin-top: 10px;
}

button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.advancedOptionsButton {
  margin-top: 10px;
  padding: 10px;
  background-color: #6c757d;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.advancedOptionsButton:hover {
  background-color: #5a6268;
}

.advancedOptions {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}
