/* src/components/SubscriptionList.module.css */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.subscriptionList {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Reduced gap to reduce empty space */
  width: 100%;
  max-width: 800px;
}

.subscriptionItem {
  display: flex;
  flex-direction: column;
  padding: 10px 20px; /* Adjusted padding to reduce empty space */
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}

.subscriptionItem p {
  margin: 5px 0; /* Reduced margin to reduce empty space */
}

.deleteButton {
  position: absolute;
  top: 10px; /* Adjusted position for better alignment */
  right: 10px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  min-width: 24px; /* Added min-width for better alignment */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
  padding: 0;
}

.deleteButton:hover {
  background-color: #c82333;
}
