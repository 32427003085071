/* src/components/ShiftList.module.css */
.container {
  display: flex;
  flex-direction: row;
  gap: 20px; /* TODO: change to row later */
}
.shiftList {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.weekContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.shiftItem {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 10px 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.shiftItem p {
  margin: 5px 0;
}

.deleteButton {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
  padding: 0;
}

.deleteButton:hover {
  background-color: #c82333;
}

h3, h4 {
  margin: 0;
}
@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
  } 
}